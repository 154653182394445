'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'next/navigation';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import { Divider, Eyebrow } from '@web/atoms';
import { useReporter } from '../../reporter';
import { BoxedCountdownClock } from '../BoxedCountdownClock';
import { SocialButton } from '../SocialButton';
import { EmailCapture } from '../EmailCaptureBar/EmailCapture';
import { orientationSettings } from './constants';
import { heroButtonClicked, appStoreCTAButtonClicked } from './events';
import {
  StyledHero,
  BackgroundImage,
  ContentContainer,
  Content,
  Header,
  Subheader,
  ButtonWrapper,
  CountdownAndLink,
  InputWrapper,
  SocialButtonWrapper,
  DividerContainer,
  Footer,
} from './styles.js';

export const Hero = ({
  headline,
  headlineVariants,
  subheadline,
  cta,
  image,
  backgroundColor = 'background.base',
  fontColor = 'text.primary',
  horizontalOrientation = 'left',
  largeScreenHorizontalOrientation = 'left',
  ctaColor,
  targetDate = null,
  eyebrow,
  socialButtons,
  divider = false,
  footer,
}) => {
  const reporter = useReporter();
  const searchParams = useSearchParams();
  const headlineVariant = searchParams?.get('hpkeyword');

  if (headlineVariant && headlineVariants) {
    const variant = headlineVariants.find(e => e.variant === headlineVariant);
    if (variant) {
      headline = variant.heading;
    }
  }

  return (
    <StyledHero backgroundColor={backgroundColor}>
      <BackgroundImage image={image}>
        <ResponsiveImage
          {...image}
          priority
          objectFit={image.picture ? 'cover' : undefined}
          sizes="(max-width: 1200px) 100vw, (max-width: 1920px) 50vw, 33vw"
        />
      </BackgroundImage>
      <ContentContainer
        image={image}
        horizontalOrientation={orientationSettings[horizontalOrientation]}
        largeScreenHorizontalOrientation={
          orientationSettings[largeScreenHorizontalOrientation]
        }
      >
        <Content
          horizontalOrientation={orientationSettings[horizontalOrientation]}
          largeScreenHorizontalOrientation={
            orientationSettings[largeScreenHorizontalOrientation]
          }
        >
          {eyebrow && <Eyebrow fontColor={fontColor}>{eyebrow}</Eyebrow>}
          {headline && <Header $fontColor={fontColor}>{headline}</Header>}
          {subheadline && (
            <Subheader $fontColor={fontColor}>{subheadline}</Subheader>
          )}
          {(targetDate || cta) && (
            <CountdownAndLink>
              {cta && cta.component === 'email_capture' ? (
                <InputWrapper>
                  <EmailCapture {...cta} buttonVariant={ctaColor} />
                </InputWrapper>
              ) : (
                cta && (
                  <ButtonWrapper backgroundColor={backgroundColor}>
                    <CtaButton
                      href={cta.target}
                      variant={ctaColor}
                      onClick={() =>
                        reporter.tag(heroButtonClicked(cta.text, cta.target))
                      }
                    >
                      {cta.text}
                    </CtaButton>
                  </ButtonWrapper>
                )
              )}
              {targetDate && (
                <BoxedCountdownClock
                  targetDate={targetDate}
                  textColor={fontColor}
                />
              )}
            </CountdownAndLink>
          )}
          {socialButtons?.length && (
            <SocialButtonWrapper>
              {socialButtons.map((button, key) => (
                <SocialButton
                  key={key}
                  {...button}
                  onClick={() => reporter.tag(appStoreCTAButtonClicked())}
                />
              ))}
            </SocialButtonWrapper>
          )}
          {divider && (
            <DividerContainer>
              <Divider direction="horizontal" color="light" />
            </DividerContainer>
          )}
          {footer && <Footer $fontColor={fontColor}>{footer}</Footer>}
        </Content>
      </ContentContainer>
    </StyledHero>
  );
};

Hero.displayName = 'Hero';

Hero.propTypes = {
  headline: PropTypes.node,
  headlineVariants: PropTypes.array,
  subheadline: PropTypes.string,
  cta: PropTypes.object,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  image: PropTypes.object.isRequired,
  horizontalOrientation: PropTypes.oneOf(['left', 'center', 'right']),
  largeScreenHorizontalOrientation: PropTypes.oneOf([
    'left',
    'center',
    'right',
  ]),
  ctaColor: PropTypes.string,
  targetDate: PropTypes.instanceOf(Date), //eslint-disable-line react/forbid-prop-types,
  eyebrow: PropTypes.string,
  socialButtons: PropTypes.array,
  divider: PropTypes.bool,
  footer: PropTypes.element,
};
