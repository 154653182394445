'use client';
import styled, { css } from 'styled-components';

export const StyledHero = styled.div`
  background-color: ${props => props.theme.colors.get(props.backgroundColor)};
  position: relative;
  text-align: center;
  z-index: 2;
`;

export const BackgroundImage = styled.div`
  max-height: 240px;
  min-height: 240px;
  overflow: hidden;

  @media ${props => props.theme.tablet} {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    min-height: unset;
    max-height: 100%;
  }

  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media ${props => props.theme.tablet} {
      height: 100%;
    }
  }
`;

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: ${props => props.horizontalOrientation.justify};
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  ${props => {
    if (props.image.srcSet) {
      return props.image.srcSet
        .map(src =>
          src.media
            ? css`
                @media ${src.media} {
                  min-height: ${src.aspectRatio.split('%')[0]}vw;
                }
              `
            : css`
                min-height: ${src.aspectRatio.split('%')[0]}vw;
              `,
        )
        .reverse();
    }

    return css`
      min-height: ${((props.image.height / props.image.width) * 100).toFixed(
        2,
      )}vw;
    `;
  }}

  @media ${props => props.theme.tablet} {
    justify-content: ${props => props.largeScreenHorizontalOrientation.justify};
  }

  @media (max-width: 767px) {
    min-height: unset;
  }
`;

export const Content = styled.div`
  align-items: ${props => props.horizontalOrientation.align};
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(4, 5, 6)};
  text-align: ${props => props.horizontalOrientation.text};
  width: 100%;
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(8, 10, 16)};
    width: 70%;
    align-items: ${props => props.largeScreenHorizontalOrientation.align};
    text-align: ${props => props.largeScreenHorizontalOrientation.text};
  }
  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(10, 20, 32)};
  }
`;

export const Header = styled.div`
  :is(h1, h2, h3, h4, h5, h6) {
    ${props => props.theme.typography.heading3}
  }
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  min-height: calc(
    ${props => props.theme.typography.heading3['font-size']} *
      ${props => props.theme.typography.heading3['line-height']}
  );
  display: inline-block;
  width: 100%;
  overflow: hidden;
  @media ${props => props.theme.tablet} {
    margin-bottom: ${props => props.theme.spacing(4)};
  }
`;

export const Subheader = styled.p`
  ${props => props.theme.typography.heading5}
  margin: ${props => props.theme.spacing(4, 0)};
  max-width: 400px;
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  min-height: calc(
    ${props => props.theme.typography.heading5['font-size']} *
      ${props => props.theme.typography.heading5['line-height']}
  );
  display: inline-block;
  width: 100%;
  overflow: hidden;
  @media ${props => props.theme.tabletS} {
    margin-top: ${props => props.theme.spacing(0)};
  }
`;

export const ButtonWrapper = styled.div`
  margin: ${props => props.theme.spacing(2, 0, 8)};
  ${props => props.theme.getFocusOutline(props.backgroundColor)}
  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(6, 0, 0)};
  }
  width: fit-content;
`;

export const SocialButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};

  @media ${props => props.theme.tabletS} {
    text-align: left;
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
`;

export const CountdownAndLink = styled.div`
  text-align: center;
  @media ${props => props.theme.laptop} {
    text-align: left;
  }
`;

export const InputWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(20)};
  width: 110%;
  margin-left: -5%;
  @media ${props => props.theme.tabletL} {
    margin-top: ${props => props.theme.spacing(10)};
  }
`;

export const DividerContainer = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing(2, 0, 4)};
  div {
    padding-top: ${props => props.theme.spacing(0)};
  }
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(2, 0, 0)};
    width: 60%;
    div {
      padding-left: ${props => props.theme.spacing(0)};
    }
  }
`;

export const Footer = styled.div`
  ${props => props.theme.typography.heading5}
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  width: 100%;
  overflow: hidden;
  padding-bottom: ${props => props.theme.spacing(4)};

  @media ${props => props.theme.tabletS} {
    margin-top: ${props => props.theme.spacing(4)};
    padding-bottom: ${props => props.theme.spacing(0)};
    max-width: 50%;
  }
  @media ${props => props.theme.laptopL} {
    max-width: 100%;
  }
`;
